<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防培训'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="根据标题查询"
          v-model="searchQuery.trainName"
          class="searchInput"
        >
          <template slot="prepend">设备名称</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/fireTrain",
      searchVal: "",
      jobList: [],
      searchQuery: { trainName: "" },
      props: [
        {
          label: "培训标题",
          prop: "trainName",
          align: "center",
          width: "250px",
        },

        {
          label: "培训单位",
          prop: "trainUnit",
          align: "center",
          width: "200px",
        },
        {
          label: "培训日期",
          prop: "trainDate",
          align: "center",
        },

        {
          label: "创建时间",
          prop: "createtime",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "培训标题",
          prop: "trainName",
          type: "input",
        },

        {
          label: "培训单位",
          prop: "trainUnit",
          type: "input",
        },
        {
          label: "培训日期",
          prop: "trainDate",
          type: "date",
        },

        {
          label: "培训内容",
          prop: "trainContent",
          type: "textarea",
        },
      ],
    };
  },
  created() {},
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
